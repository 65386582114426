
  import info from "@/components/_universal/list-info.vue";
  import tablelist from "@/components/_universal/list-table.vue";

  // Standard Components
  import EVENTS from "@/constants/events";

  // Controller
  import InvoiceController from "@/controllers/invoice-controller";
  import listMixin from "@/mixin/list-mixin";

  // Initialize the controller (reuse the same instance)
  const invoiceController = new InvoiceController();

  export default {
    mixins: [listMixin],
    data() {
      return {
        listDataObj: {},
        EVENT_ACTION: {},
        project: {},
        timer: null,         // Holds the interval timer ID.
        apiCallCount: 0,     // Tracks the total number of API calls.
        unsubscribe: null,   // Holds the store subscription function.
      };
    },
    methods: {
      async onFilterChange() {
        // Get current filter from store, if available.
        if (this.$store.getters.getList.filter) {
          this.filter = this.$store.getters.getList.filter;
        }
        // Call the API using the controller.
        let data = await invoiceController.getListResults(this.filter);
        // Update the Vuex store with the results.
        await this.updateResults(data.resources);
      },
      async loadData() {
        // Load initial list data via the controller.
        this.listDataObj = await invoiceController.list();
        // If data retrieval was successful, update the store.
        if (this.listDataObj.table.data.success) {
          this.updateResults(this.listDataObj.table.data.resources);
        }
        await invoiceController.backgroundjob();
      },
      async updateResults(results) {
        // Dispatch the new list results to the Vuex store.
        this.$store.dispatch("setList", results);
      },
      subscribeOnFilterChange() {
        // Subscribe to filter changes in the store.
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
          if (mutation.type === "setFilter") {
            this.onFilterChange();
          }
        });
      },
    },
    components: {
      info,
      tablelist,
    },
    async mounted() {
      // Optionally track events (uncomment in production):
      // if (process.env.VUE_APP_ENVIRONMENT === "production") {
      //   window["analytics"].page(this.EVENT_ACTION.INVOICE.View);
      // }
      this.subscribeOnFilterChange();
      // Set up the timer to call onFilterChange() every 20 seconds.
      this.timer = setInterval(async () => {
        // If the API call count has reached 30, stop further calls.
        if (this.apiCallCount >= 100) {
          clearInterval(this.timer);
          this.timer = null;
          
          return;
        }
        await this.onFilterChange();
        this.apiCallCount++;
       
      }, 15000); // 20 seconds interval
    },
    async created() {
      // Import event constants.
      this.EVENT_ACTION = EVENTS;
      // Load initial data.
      await this.loadData();
    },
    beforeDestroy() {
      // Clean up: reset store data.
      this.updateResults(null);
      // Unsubscribe from store changes.
      if (this.unsubscribe) {
        this.unsubscribe();
      }
      // Clear the timer if it exists.
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
  };
